export const uploadConfig = {
    banner: {
        type: ['image'],
        limit: 1
    },
    thumbnail: {
        type: ['image'],
        limit: 1
    },
    album: {
        type: ['image'],
        limit: 10
    }

};
export const formItems = [{
    label: '场景包名称',
    prop: 'scene_pack_name',
    placeholder: '最多可输入20个字符，仅后台可见，供运营备注使用'
}, {
    label: '场景包标题（中文）',
    prop: 'name',
    placeholder: '最多可输入50个字符，展示在中文版前端场景包详情页的标题位置'
}, {
    label: '场景包描述（中文）',
    prop: 'description',
    placeholder: '最多可输入200个字符，展示在中文版前端场景包详情页的描述位置'
}, {
    label: '场景包标题（英文）',
    prop: 'name_en',
    placeholder: '最多可输入100个字符，展示在英文版前端场景包详情页的标题位置'
}, {
    label: '场景包描述（英文）',
    prop: 'description_en',
    placeholder: '最多可输入400个字符，展示在英文版前端场景包详情页的描述位置'
}];
export const initForm = {
    scene_pack_name: '',
    name: '',
    description: '',
    name_en: '',
    description_en: '',
    banner: null,
    thumbnail: null, // 缩略图链接
    album: [], // 副图
    publish_type: '1',
    publish_time: '',
    list_order: '',
    discount: ''
};
/**
 * 折扣校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateDiscount = (rule, value, callback) => {
    const reg = /^(\d{1,2})$/;
    if (!(reg.test(value) && value >= 1 && value <= 99)) {
        callback('只能输入数字，必须为整数，数值范围1-99');
    } else {
        callback();
    }
};
/**
 * 顺序校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateListOrder = (rule, value, callback) => {
    const reg = /^(\d)+$/;
    if (value && !reg.test(value)) {
        callback('只能输入数字，必须为整数');
    } else {
        callback();
    }
};
/**
 * 英文校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateEn = (rule, value, callback) => {
    const reg = /[\u4E00-\u9FA5]/g;
    if (value && reg.test(value)) {
        callback('不能输入中文');
    } else {
        callback();
    }
};
export const rules = {
    scene_pack_name: [
        { required: true, message: '请输入场景包名称', trigger: 'blur' },
        { min: 1, max: 20, message: '最多可输入20个字符', trigger: 'blur' }
    ],
    name: [
        { required: true, message: '请输入场景包标题（中文）', trigger: 'blur' },
        { min: 1, max: 50, message: '最多可输入50个字符', trigger: 'blur' }
    ],
    description: [
        { required: true, message: '请输入场景包描述（中文）', trigger: 'blur' },
        { min: 1, max: 200, message: '最多可输入200个字符', trigger: 'blur' }
    ],
    name_en: [
        { required: true, message: '请输入场景包标题（英文）', trigger: 'blur' },
        { validator: validateEn, trigger: 'blur' },
        { min: 1, max: 100, message: '最多可输入100个字符', trigger: 'blur' }
    ],
    description_en: [
        { required: true, message: '请输入场景包描述（英文）', trigger: 'blur' },
        { validator: validateEn, trigger: 'blur' },
        { min: 1, max: 400, message: '最多可输入400个字符', trigger: 'blur' }
    ],
    banner: [
        { required: true, message: '请上传场景包主图', trigger: 'change' }
    ],
    thumbnail: [
        { required: true, message: '请上传场景包缩略图', trigger: 'change' }
    ],
    publish_type: [
        { required: true }
    ],
    publish_time: [
        { required: true, message: '请选择定时发布时间', trigger: 'change' }
    ],
    discount: [
        { required: true, message: '请输入省下折扣' },
        { validator: validateDiscount, trigger: 'blur' }
    ],
    list_order: [
        { validator: validateListOrder, trigger: 'blur' }
    ]
};
