<template>
    <div v-if="hasResource('scene_pack_add')" class="aops-scene-add">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-add-title">{{id ? '编辑' : '新增'}}场景包</div>
        <div v-loading="isLoading">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="150px"
            >
                <el-form-item
                    v-for="item in formItems"
                    :key="item.prop"
                    :label="item.label"
                    :prop="item.prop"
                    class="aop-form-item"
                >
                    <el-input
                        v-model="form[item.prop]"
                        :placeholder="item.placeholder"
                    />
                </el-form-item>
                <el-form-item prop="banner" class="aop-form-item">
                    <template slot="label">
                        <div>
                            场景包主图<span class="aop-label-tip">场景详情页展示的第一张图</span>
                        </div>
                    </template>
                    <uploadImg
                        :config="uploadConfig['banner']"
                        :upload-image="form.banner"
                        @onChange="(img) => handleImgChange('banner', img)"
                    />
                </el-form-item>
                <el-form-item prop="thumbnail" class="aop-form-item">
                    <template slot="label">
                        <div>
                            场景包缩略图<span class="aop-label-tip">在列表等位置展示的场景包效果图</span>
                        </div>
                    </template>
                    <uploadImg
                        :config="uploadConfig['thumbnail']"
                        :upload-image="form.thumbnail ? {type: 1, image_url:form.thumbnail} : null"
                        @onChange="(img) => handleImgChange('thumbnail', img)"
                    />
                </el-form-item>
                <el-form-item label="场景包副图" prop="album" class="aop-form-item">
                    <uploadImg
                        :config="uploadConfig['album']"
                        :img-list="form.album"
                        @onChange="(img) => handleImgChange('album', img)"
                    />
                </el-form-item>
                <el-form-item label="发布时间" class="aop-form-item" prop="publish_type">
                    <el-radio-group v-model="form.publish_type">
                        <el-radio label="1">立即发布</el-radio>
                        <el-radio label="2">
                            定时发布
                            <el-form-item v-if="form.publish_type === '2'" label="" prop="publish_time">
                                <el-date-picker
                                    v-model="form.publish_time"
                                    value-format="timestamp"
                                    type="datetime"
                                    placeholder="请选择定时发布时间"
                                ></el-date-picker>
                            </el-form-item>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="展示位置" class="aop-form-item" prop="list_order">
                    <el-input v-model="form.list_order" />
                    <div class="aop-label-tip">根据已有顺序，系统自动生成数字，默认生成在列表尾部，修改数字可调整顺序，数字相同会插入原本的位置。</div>
                </el-form-item>
                <div class="aop-form-item-title"><span class="aop-title-text">关联资产</span></div>
                <selectAssets :select-assets.sync="selectAssets" :max-quantity="100" :disabled="disabled"/>
                <!-- <el-form-item label="省下折扣" prop="discount" class="aop-form-discount">
                    <el-input v-model="form.discount" />%
                    <div class="aop-label-tip">{{discountTip}}</div>
                </el-form-item> -->
            </el-form>
            <div class="aop-activity-footer">
                <el-button type="primary" class="aop-confirm-button" :loading="btnLoading" @click="handleConfirm">保存</el-button>
                <el-button class="cancel-button" @click="handleBack">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api/scene';
import selectAssets from '@/pages/_common/selectAssets/selectAssets';
import uploadImg from '@/pages/_common/uploadImg/uploadImg';
import { uploadConfig, initForm, formItems, rules } from './data';

export default {
    components: { selectAssets, uploadImg },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            isLoading: false,
            form: { ...initForm },
            rules,
            formItems,
            uploadConfig,
            selectAssets: [], // 已选资产
            btnLoading: false,
            disabled: false // 是否可以编辑关联资产
        };
    },
    computed: {
        /**
         * id
         * @return {string}
         */
        id() {
            return this.$route.query.id;
        },
        /**
         * 折扣文案
         * @return {string}
         */
        discountTip() {
            let text = '';
            if (this.form.discount && this.selectAssets.length) {
                const points = this.selectAssets.map((item) => item.cost);
                const allPoints = points.reduce((total, num) => total + num, 0);
                const dis = allPoints * (1 - this.form.discount / 100);
                text = `总计${allPoints}积分，折后${Math.floor(dis)}积分`;
            }
            return text;
        }
    },
    /**
     * mounted
     */
    mounted() {
        if (this.id) {
            this.getSceneDetail();
        }
    },
    methods: {
        /**
         * 获取场景包详情
         */
        getSceneDetail () {
            this.isLoading = true;
            Api.sceneDetail({ scene_pack_id: this.id }).then((res) => {
                if (res.data.code === 10200) {
                    const data = res.data.data || {};
                    this.form = data;
                    this.form.publish_time = data.publish_time ? data.publish_time * 1000 : '';
                    this.selectAssets = data.asset_list || [];
                    // 启用过，不可编辑关联资产
                    this.disabled = `${data.status}` === '1' || data.enable_version > 0;
                } else {
                    this.$$error(res.data.message);
                }
                this.isLoading = false;
                // this.$nextTick(() => {
                //     // 滚动到顶部
                //     this.$refs.activityIntro.scrollTop = 0;
                // });
            }).catch(() => {
                this.isLoading = false;
            });
        },
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/operations/scene');
        },
        /**
         * 处理图片/视频，更改form
         * @param {*} prop {prop}
         * @param {*} img 添加{type, url} / 删除{oper, index}
         */
        handleImgChange(prop, img) {
            if (img.oper && img.oper === 'del') {
                this.form[prop].splice(img.index, 1);
                return;
            }
            const config = uploadConfig[prop];
            const data = {
                [`${img.type === 1 ? 'image' : 'video'}_url`]: img.url,
                type: img.type
            };
            if (config.limit === 1) {
                this.form[prop] = prop === 'thumbnail' ? img.url : data;
            } else {
                this.form[prop] = [...this.form[prop], data];
            }
            this.$refs.form.validateField([prop]);
        },
        /**
         * 点击确认按钮
        */
        handleConfirm() {
            if (this.selectAssets.length === 0) {
                this.$$warning('请选择关联资产');
                return;
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    Api.sceneSave({
                        id: this.id || '',
                        ...this.form,
                        assets: this.selectAssets.map((item) => item.asset_uid),
                        publish_time: this.form.publish_type === '1' ? '' : this.form.publish_time / 1000
                    }).then((res) => {
                        if (res.data.code === 10200) {
                            this.$$success('场景包保存成功');
                            this.handleBack();
                        } else {
                            this.$$error(res.data.message);
                        }
                        this.btnLoading = false;
                    }).catch(() => {
                        this.btnLoading = false;
                    });
                }
            });
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
